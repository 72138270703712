html {
  font-size: 0.9rem;
}

html body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  overflow: auto !important;
}

label,
input,
button,
select,
textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

div a:not(#mainNavbar a) {
  color: #08c;
}

.page-title {
  background-color: #0181af;
  color: white;
  padding: 0 20px;
  width: 100%;
  height: 47px;
  display: flex;
  justify-content: space-between;
}

.page-title h1 {
  font-size: 20px;
  margin: 0;
  padding: 10px 0;
}

div .hoverable-tag {
  text-decoration: none;
}

div .hoverable-tag:hover {
  text-decoration: underline;
}
div .progress-bar {
  background-color: #0181af;
}

/******** overide table css ********/
.table-pager .pagination-small ul > li > a,
.table-pager .pagination-small ul > li > span {
  padding: 1px 10px;
}

.table-pager .quick-search-badges {
  margin: 0 5px 5px 5px;
}

.table-pager .quick-search-badge {
  text-decoration: none;
}

.table-pager .btn--quick-search {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

.table-pager .btn--quick-search input {
  padding-bottom: 1px;
  padding-top: 1px;
  width: 200px;
}

.table-pager .btn--quick-search a {
  padding-bottom: 1px;
  padding-top: 1px;
}

.table-pager .btn--pages,
.table-pager .btn--items-per-page {
  margin-left: 5px;
}

.table-pager .btn--save {
  font-size: 14px;
}

.table th,
.table td {
  padding: 4px !important;
}

.table thead {
  background-color: #d9edf7;
}

.clickable {
  cursor: pointer;
}
.movex-color {
  color: #0181af;
}

/*** job page colors ***/
.search-box-colors{
  background-color: #f5f5f5;
}

/******* Override MuiDataGrid css *************/
div div .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable,
div div .MuiDataGrid-root div .MuiDataGrid-cell {
  padding: 0px;
}
.MuiDataGrid-main .col-header.sortable {
  cursor: pointer;
  text-decoration: underline;
}
.MuiDataGrid-main th,
.MuiDataGrid-main td {
  padding: 4px !important;
}
.MuiDataGrid-main .MuiDataGrid-columnHeaders {
  background-color: #d9edf7;
}
.MuiDataGrid-main thead div.row-selection {
  min-width: 50px;
}
.MuiDataGrid-main thead div.row-selection input.row-selection-checkbox {
  margin: 0;
}
.MuiDataGrid-main thead div.actions {
  min-width: 50px;
}
.MuiDataGrid-main.job-grid-container div input.row-selection-checkbox {
  margin-left: 6px;
}
.MuiDataGrid-main.feedback div .feedback.fa.fa-star-o,
.MuiDataGrid-main.feedback div .feedback.fa.fa-star {
  font-size: 0.75em;
}
.MuiDataGrid-main.price-groups thead div.actions {
  text-align: center;
  width: 50px;
}
.MuiDataGrid-main.prices thead div.actions {
  text-align: center;
  width: 50px;
}
.MuiDataGrid-main.administrators tr.inactive {
  background-color: #cccccc;
}
.MuiDataGrid-main.adminroles div.id,
.MuiDataGrid-main.adminroles div.actions {
  width: 50px;
}
.MuiDataGrid-main a {
  color: #0181af;
}
div .MuiTableCell-head,
div .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
  text-decoration: underline;
  color: #0076a6;
  padding: 2px;
}
div .MuiTableHead-root {
  background-color: #d9edf7;
}
div .MuiTableRow-root.MuiTableRow-head th,
div .MuiDataGrid-columnsContainer {
  background-color: #d9edf7;
  border-bottom: 1px solid #000;
}
div .MuiTableBody-root .MuiTableCell-root {
  padding: 4px;
  max-width: 250px;
  word-wrap: break-word;
}
div .MuiPaper-root > .MuiToolbar-root {
  display: none !important;
}
div .MuiDataGrid-columnHeaderTitleContainer {
  padding: inherit;
}
div.disabled.MuiDataGrid-row {
  background-color: #ffd0d0;
}
div.inactive.MuiDataGrid-row {
  background: #cccccc;
}
.MuiDataGrid-main .grey-row td {
  background-color: #eee;
  border-top: none;
}
.MuiDataGrid-main .white-row td {
  background-color: #fff;
  border-top: none;
}
.MuiDataGrid-main .orange-row td {
  background-color: #ffdab5;
}
.job-grid-container .MuiDataGrid-main .declined td {
  background-color: #ffd0d0;
}
.job-grid-container .MuiDataGrid-main .has-maximum-margin td {
  background-color: #f5d8ff;
}
.job-grid-container .MuiDataGrid-main .has-quote-within-fixed-price-range td {
  background-color: #d0ffd0;
}
.job-grid-container .MuiDataGrid-main div .Aborted {
  background-color: red;
  border-radius: 10px;
  color: white;
  padding: 5px 10px;
}
.job-grid-container .MuiDataGrid-main div .Expired,
.job-grid-container .MuiDataGrid-main div .Cancelled,
.job-grid-container .MuiDataGrid-main div .Admin_Removed___Failed,
.job-grid-container .MuiDataGrid-main div .Unknown {
  color: black;
}
.job-grid-container
  .MuiDataGrid-main
  .job-grid-container
  div
  input.row-selection-checkbox {
  margin-left: 6px;
}
.MuiDataGrid-main.feedback div .feedback.fa.fa-star-o,
.MuiDataGrid-main.feedback div .feedback.fa.fa-star {
  font-size: 0.75em;
}
.MuiDataGrid-main.price-groups thead div.actions {
  text-align: center;
  width: 50px;
}
.MuiDataGrid-main.prices thead div.actions {
  text-align: center;
  width: 50px;
}
.MuiDataGrid-main.administrators tr.inactive {
  background-color: #cccccc;
}
div div .MuiDataGrid-root {
  flex-direction: column;
}
/* override mui pagination  */
div .Mui-selected.MuiPaginationItem-root {
  background-color: #0181af;
  color: #fff;
}
div .MuiTablePagination-root.MuiToolbar-root div:not(.MuiTablePagination-input),
div .MuiTablePagination-root.MuiToolbar-root div.MuiTablePagination-selectLabel,
.MuiTablePagination-input:not(div#divNewMembersGrid .MuiTablePagination-input),
div .MuiTablePagination-root .MuiToolbar-root > p {
  padding-top: 1px;
}
div
  .MuiTablePagination-input:not(div#divNewMembersGrid
    .MuiTablePagination-input) {
  top: initial;
}
div .MuiTablePagination-caption,
div .MuiDataGrid-toolbarContainer button {
  color: #0181af;
}
div .MuiDataGrid-toolbarContainer button {
  text-transform: capitalize;
}

/******** override bootstrap  *****************/
ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: 40px;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

.non-underline {
  text-decoration: none;
}

div .MuiTooltip-tooltip {
  font-size: 15px;
}

.well {
  min-height: 20px;
  margin: 10px 0;
  font-size: 12px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  line-height: 14px;
}

.please-search-placeholder {
  margin: 30px 0;
  text-align: center;
}
.please-search-placeholder .please-search-text {
  font-size: 20px;
}

div .form-check-input:checked {
  background-color: #0181af;
  border-color: #0181af;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

div label {
  font-weight: 500;
}

div .nav-link:hover,
div .nav-link:focus,
div .nav-link {
  color: #0181af;
}

.f-r {
  float: right;
}

.btn-movex-cancel {
  color: #ffff;
}

button {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.add-new-icon {
  cursor: pointer;
  margin: 5px;
}

.ta-r {
  text-align: right;
}

.ta-l {
  text-align: left;
}

.standard-page .hero-row {
  background: transparent url(./Assets/Images/Redesign/hero-road.jpg) no-repeat
    0 0;
  background-size: cover;
  color: #fff;
  text-align: center;
}

.main {
  font-size: 0.85em;
}

tbody tr td {
  font-size: 12px;
}

/*member dropdown*/
.dropdown-tsandcs-invalid{
  background-color: #d0d0ff;
}
.dropdown-payment-not-setup{
  background-color: #f8c25e;
}
.dropdown-inactive{
  background-color: #cccccc;
}
.dropdown-disabled{
  background-color: #ffd0d0;
}


.react-datepicker__day.validday {
  background-color: #dff0d8;
  border-radius: 0.3rem;
}


/** Grid material ui header Manual change. Don't think there is a better way for now... */
.headerStyleTest{
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  white-space: normal !important;
  hyphens: auto !important;
} 

.grid-custom-header {
  white-space: normal;
  word-wrap: break-word;
  line-height: normal;
  min-height: auto;
  max-height: none;
  font-weight: bold;
  text-decoration: underline;
  color: #0076a6;
  padding: 2px;
}

.css-okt5j6-MuiDataGrid-columnHeaders {
  line-height: 20px !important;
}
/** END of Grid material ui header ... */

.grid-color--orange{
  background-color: #ffdab5;
}

.grid-color--success{
  background-color: #d0e9c6;
}

.grid-color--info{
  background-color: #c4e3f3;
}

.grid-color--warning{
  background-color:#faf2cc;
}

.grid-color--error{
  background-color: #ebcccc;
}

/** Tasks - status dropdown */
.selectable-option {
  background-color: white;
  color: #333;
}

.non-selectable-option {
  background-color: #e0e0e0;
  color: #999;
}

/** Jobs - Admin Notes */
div .pre-line {
  white-space: pre-line;
}

.no-bottom-border {
  border-bottom: transparent !important;
}
