.table tr.inactive {
  background: #cccccc !important;
}

.table tr.disabled,
.table tr.disabled > td {
  background-color: #ffd0d0 !important;
}

.table > :not(caption) > * > * {
  max-width: 250px;
  word-wrap: break-word;
}

/* Responsive tables */
.rwd-table {
  margin: 1em 0;
  min-width: 300px;
  display: table;
  overflow: hidden;
}
.rwd-table tr {
  border-bottom: 1px solid #ddd;
  border-color: #46627f;
}
.rwd-table th {
  display: table-cell;
  padding: 0;
}
.rwd-table td {
  display: table-cell;
  padding: 0;
}
.rwd-table td:first-child {
  padding-top: 0.5em;
}
.rwd-table td:last-child {
  padding-bottom: 0.5em;
}
.rwd-table td:before {
  content: attr(data-th) ': ';
  font-weight: bold;
  width: 13em;
  display: none;
}
.rwd-table th,
.rwd-table td {
  text-align: left;
  border-top: 1px solid #ddd;
  padding: 1em !important;
}
.rwd-table th:first-child,
.rwd-table td:first-child {
  padding-left: 0;
}
.rwd-table th:last-child,
.rwd-table td:last-child {
  padding-right: 0;
}

@media only screen and (max-width: 979px) {
  #transport-companies-left-nav a.btn i.fa,
  #motor-dealers-left-nav a.btn i.fa {
    display: none;
  }

  /* BEGIN responsive tables */

  .rwd-table td:before {
    display: inline-block;
  }
  .rwd-table th {
    display: none;
  }
  .rwd-table td {
    display: block;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 0 !important;
    border-top: 0;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: auto;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: auto;
  }
  .rwd-table td:first-child {
    padding-top: 0.5em !important;
  }
  .rwd-table td:last-child {
    padding-bottom: 0.5em !important;
  }

  /* END responsive tables */
}
@media only screen and (max-width: 767px) {
  #topnav ul,
  #footer ul,
  .large-nav {
    display: none;
  }

  #topnav select,
  #footer select,
  .tiny-nav {
    display: block;
  }

  table {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  #helpline {
    text-align: right;
  }

  #header-container #user-logged-in-form {
    display: none;
  }

  #header-container > div:last-child {
    min-height: 0;
  }

  #sublogo {
    display: none;
  }

  #uk-outline {
    display: none;
  }

  #AcceptQuoteDialog {
    width: auto;
    margin-left: 0;
  }

  .slider {
    margin-bottom: 25px;
  }

  .slider .tooltip {
    opacity: 100;
    top: 10px !important;
  }

  .tooltip.top {
    margin-top: 3px !important;
  }

  .tooltip.top .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: #000;
    border-width: 0 5px 5px;
  }

  .tabbable {
    display: inline-block;
  }
}
@media print {
  #header-container,
  h1 {
    margin: 0;
    padding: 0;
  }

  .no-print {
    display: none !important;
  }
}

thead tr td,
thead tr th {
  color: #0076a6;
  font-size: 14px;
  font-weight: bold;
}
