.new-members-container {
  margin: 0 10px;
}

.underline {
  text-decoration: underline;
}

.quick-search-badges-div,
.quick-search-badge {
  margin: 5px;
}

.quick-search-label {
  display: inline-block;
  padding: 2px 4px;
  font-size: 11.844px;
  font-weight: bold;
  line-height: 14px;
  color: #fff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999;
  border-radius: 3px;
  cursor: pointer;
}

.blue-quick-search-badge {
  display: inline-block;
  padding: 2px 4px;
  font-size: 11.844px;
  font-weight: bold;
  line-height: 14px;
  color: #fff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #3a87ad;
  border-radius: 3px;
  cursor: pointer;
}
.quick-search-btn {
  margin: 0 5px;
}

@media (min-width: 576px) {
  div.jobs-container .modal-dialog {
    max-width: 70%;
  }
}

.text-right-fw-600 {
  font-weight: 600;
  text-align: right;
  width: 130px;
}

.has-quote-within-fixed-price-range {
  background-color: #d0ffd0;
}
.has-quote-within-fixed-price-range:hover,
.has-quote-within-fixed-price-range.Mui-selected {
  background-color: #c6f2c6 !important;
}

.has-max-margin {
  background-color: #f5d8ff;
}

.has-max-margin:hover,
.has-max-margin.Mui-selected {
  background-color: #e6c0e6 !important;
}