/*override bootstrap css for navbar */
div .dropdown-menu[data-bs-popper] {
  margin-top: 0px;
}

div .navbar-light .dropdown-item {
  border: 1px solid #fff;
}

div .navbar-light .navbar-nav .nav-link {
  color: #000;
  font-weight: 500;
}

.inactive.nav-item.dropdown {
  color: #fff;
}

/* Styles for screens 992px and larger */
@media (min-width: 992px) {

  .dropdown-item.active,
  .dropdown-item:hover,
  .dropdown-item:active,
  .nav-item.dropdown:hover {
    background-color: #0181af !important;
    color: #fff !important;
  }

  .inactive.nav-item.dropdown {
    border-bottom: 1px solid #0181af;
  }

  div .navbar-light .navbar-nav .active .nav-link,
  div .navbar-light .navbar-nav:hover,
  div .navbar-light .navbar-nav .nav-link:hover,
  div .navbar-light .navbar-nav .nav-link.active {
    color: #fff;
  }

  div .navbar {
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    border-bottom: 1px solid #0181af;
    height: 40px;
  }

  .navbbar {
    width: 100%;
    height: 40px;
  }

  .nav-item.dropdown {
    padding: 0 5px;
    line-height: 22px;
  }

  div .navbar-brand {
    padding: 0;
    margin: 0;
  }

  .active.nav-item.dropdown {
    background-color: #0181af;
    color: #fff;
    border-color: #999;
  }
}

/* Styles for screens smaller than 992px */
@media (max-width: 991px) {

  .dropdown-item.active,
  .dropdown-item:hover,
  .dropdown-item:active,
  .nav-item.dropdown:hover {
    background-color: #2694bc;
    color: #fff !important;
    border-radius: 5px;
  }

  .active.nav-item.dropdown {
    background-color: #0181af !important;
    color: #fff;
    border-color: #999;
    border-radius: 5px;
  }

  div .navbar {
    /* padding-bottom: 0px; */
    padding-top: 0px;
    /* margin-bottom: 0px; */
    border-bottom: 2px solid #0181af;
    height: auto;
  }

}