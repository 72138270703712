div .btn-light {
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
}

div .btn-light :hover {
  color: #333;
  text-decoration: none;
  background-color: #e6e6e6;
  background-position: 0 -15px;
  transition: background-position 0.1s linear;
  border-color: rgba(0, 0, 0, 0.15);
}

div .container,
div .container-xxl,
div .container-xl,
div .container-lg,
div .container-md,
div .container-sm {
  margin: 10px 0;
}

div .btn-primary,
div .btn-primary:hover,
div .btn-check:checked + .btn-primary,
div .btn-check:checked + .btn-primary,
div .btn-check:active + .btn-primary,
div .btn-primary:active,
div .btn-primary.active,
div .show > .btn-primary.dropdown-toggle,
div .btn-primary:disabled,
div .btn-primary.disabled,
div .btn-check:focus + .btn-primary,
div .btn-primary:focus {
  color: #fff;
  background-color: #0181af;
  border-color: #0181af;
}

div .form-group {
  margin-bottom: 15px;
}

div .form-control[readonly] {
  cursor: not-allowed;
}

div .rbt-token-removeable {
  color: #fff;
  background-color: #3a87ad;
}

div .rbt-token .rbt-token-remove-button {
  margin: 5px;
  background-color: #3a87ad;
  top: -1px;
  padding: 0px 0px;
  border-radius: 0.25rem;
  border: none;
}

.asterisk {
  color: #dc3545;
}

.MuiRating-root > label {
  font-size: 30px;
}

.input-custom-currency-styling {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: white;
  border-right-width: 0px;
}