.add-member-outer-container .container {
  max-width: 965px;
}

#accountDisabledDiv + div {
  margin-left: 25px;
}

.TypeAheadDropDown {
  width: 100%;
  border: 1px solid gray;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px 1px rgba(0, 0, 0, 0.18);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.73);
}

.TypeAheadDropDown input {
  width: 100%;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.73);
  padding: 10px 5px;
  box-sizing: border-box;
  outline: none;
}
.TypeAheadDropDown ul::before {
  content: '';
}

.TypeAheadDropDown ul {
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
  border-top: 1px solid gray;
}

.TypeAheadDropDown li {
  padding: 10px 5px;
  cursor: pointer;
}

.TypeAheadDropDown li:hover {
  background: lightgray;
  text-decoration: underline;
}

.auto-relist-selected-supplier-jobs-container > div {
  display: inline-block;
  margin-right: 15px;
}

select option.tsandcs-invalid {
  background-color: #d0d0ff;
}
select option.payment-not-setup {
  background-color: #f8c25e;
}
select option.inactive {
  background-color: #cccccc;
}
select option.disabled {
  background-color: #ffd0d0;
}
