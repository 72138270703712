.well .container {
  max-width: initial;
}

.MuiDataGrid-toolbarContainer button {
  font-size: 16px;
  padding: 14px;
}

.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.members-container .MuiDataGrid-main {
  font-size: 12px;
}

div .MuiDataGrid-iconSeparator {
  display: none;
}

.Collapsible {
  min-height: 30px;
}

.Collapsible__trigger.is-closed,
.Collapsible__trigger.is-open {
  margin: 0 10px;
  font-size: 16px;
}

.Collapsible__trigger.is-open svg {
  transform: rotate(180deg);
}

.jobGridButtoForAssigningJobsDiv {
  display: inline-block;
  padding: .375rem .75rem;
  margin-right: 10px;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  border: 1px solid #0181af;
  border-radius: .25rem;
  cursor: pointer;
  user-select: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/* adjust the checkbox column widht of the job grid */
.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: flex-start !important;
}