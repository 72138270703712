.well .container {
    max-width: initial;
  }
  
  .MuiDataGrid-toolbarContainer button {
    font-size: 16px;
    padding: 14px;
  }
  
  .table-cell-trucate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .members-container .MuiDataGrid-main {
    font-size: 12px;
  }
  
  div .MuiDataGrid-iconSeparator {
    display: none;
  }
  
  .Collapsible {
    min-height: 30px;
  }
  
  .Collapsible__trigger.is-closed,
  .Collapsible__trigger.is-open {
    margin: 0 10px;
    font-size: 16px;
  }
  
  .Collapsible__trigger.is-open svg {
    transform: rotate(180deg);
  }
  