.group-saved-details tr.display-row td input {
  background: transparent;
  border: none;
  font-size: 12px;
  box-shadow: none;
  pointer-events: none;
}

.member-dropdown-container {
  position: relative;
  top: -1rem;
}

.member-dropdown {
  position: absolute;
  /* top: -100%; */
  left: 0;
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
  list-style-type: none;
  border-radius: 4px;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
}

.member-dropdown li {
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.2s;
}

.member-dropdown li:hover {
  background-color: #f5f5f5;
}

.job-cost-field {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: end;
}