.permission-denied-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .permission-denied-content {
    text-align: center;
    max-width: 600px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  }
  
  .permission-denied-content h1 {
    color: #0181af;
    margin-bottom: 20px;
  }
  
  .permission-denied-content p {
    margin-bottom: 10px;
  }
  
  .permission-denied-content button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #0181af;
    color: #ffffff;
    cursor: pointer;
  }
  
  .permission-denied-content button:hover {
    background-color: #0181af;
  }
  